/* eslint-disable camelcase */
// @TODO: Think about finding better solution for all those graphics in card
import { useEffect, useRef, useState } from 'react'
// Third party imports
import styled from '@emotion/styled'
import { useAmp } from 'next/amp'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { objectToArrayOrAny } from '@hmn/rtl-web-core/helpers/object-to-array-or-any'
import { useBreakpoints, useGTM, useInnerHtml } from '@hmn/rtl-web-core/hooks'

import { ContentMarker, contentMarkerSizes, contentMarkerVariants } from '../../../ContentMarker'
import {
    Frame,
    frameVariants,
    Graphic,
    graphicFillVariants,
    graphicVariants,
    Triangle,
    triangleVariants
} from '../../../Decoration/components'
import { Image, imageRatioVariants } from '../../../Image'
import { Link } from '../../../Link'
import styles from './Card.style'

const ArticleCardStyled = styled.div(props => ({ ...styles(props) }))

function ArticleCard({
    item,
    imageWidth,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    imageHeight,
    imageVariation,
    imageLazyLoad,
    titleTagComponent: TitleTag,
    href,
    hrefAs,
    className,
    variant,
    decorationVariant,
    contentMarkerVariant,
    contentMarkerSize,
    inverted,
    rightAlign,
    embedded,
    gtmData,
    imagePreload,
    columnist,
    sizeVariant,
    ...rest
}) {
    const { title, name, subtitle: label, extended_attributes } = item
    const labelToUse = label || extended_attributes?.subtitle
    const titleHtmlProps = useInnerHtml(title)
    const labelHtmlProps = useInnerHtml(labelToUse)
    const nameHtmlProps = useInnerHtml(name)
    const columnistHtmlProps = useInnerHtml(columnist)
    const [isDesktop] = useBreakpoints('md')
    const { sendDataToGTM } = useGTM()
    const isAmp = useAmp()

    const isPrevNext = (
        objectToArrayOrAny(item?.taxons?.['article-type']) ||
        objectToArrayOrAny(item?.articleType) ||
        []
    )?.some(t => t.slug === 'article-type-prev-next')
    const handleClick = () => {
        sendDataToGTM(gtmData)
    }

    const cardContent = useRef()
    const cardImage = useRef()

    const cardContentHeight = cardContent?.current?.getBoundingClientRect().height
    const cardImageHeight = cardImage?.current?.getBoundingClientRect().height

    const imageDiff = 0.3 * cardImageHeight

    const [cardJustSpaceHeight, setCardJustSpaceHeight] = useState(0)

    useEffect(() => {
        setCardJustSpaceHeight(cardContentHeight - imageDiff)
    }, [cardContentHeight, imageDiff])

    return (
        <ArticleCardStyled
            id="article_card_wrapper"
            variant={variant}
            decorationVariant={decorationVariant}
            contentMarkerVariant={contentMarkerVariant}
            contentMarkerSize={contentMarkerSize}
            inverted={inverted}
            rightAlign={rightAlign}
            embedded={embedded}
            className={className}
            isAmp={isAmp}
            sizeVariant={sizeVariant}
            {...rest}>
            <Link
                href={`${href}${isPrevNext ? '/1' : ''}`}
                as={hrefAs}
                title={title}
                className="cardInner"
                onClick={handleClick}>
                {variant !== 'zeta' && (
                    <div className="cardImage" ref={cardImage}>
                        <Image
                            image={item?.image}
                            variation={imageVariation || imageRatioVariants.CUSTOM_SQUARE}
                            useLargestInstance={rest?.imageUseLargestInstance}
                            width={imageWidth}
                            lazyLoad={imageLazyLoad}
                            hideAuthor
                            preload={!!imagePreload}
                            // height={imageHeight}
                        />
                        {decorationVariant === 'alpha' && (
                            <>
                                <Frame className="cardImage_frame" variant={frameVariants.BETA} />
                                <Graphic
                                    className="cardImage_graphic"
                                    widthXs={100}
                                    widthMd={222}
                                    heightMd={112}
                                    variant={graphicVariants.EPSILON}
                                />
                            </>
                        )}
                        {decorationVariant === 'beta' && (
                            <>
                                <Triangle className="cardImage_triangle" variant={triangleVariants.SECONDARY} />
                                <Graphic className="cardImage_graphic" variant={graphicVariants.BETA} />
                            </>
                        )}
                        {decorationVariant === 'gamma' && (
                            <>
                                <Frame className="cardImage_frame" variant={frameVariants.ALPHA} />
                                <Graphic className="cardImage_graphic" variant={graphicVariants.ZETA} />
                            </>
                        )}
                        {contentMarkerVariant !== 'none' && (
                            <div className="contentMarker_wrapper">
                                <ContentMarker
                                    id="article_card_content_marker"
                                    size={
                                        variant === 'gamma' && !isDesktop
                                            ? contentMarkerSizes.SMALL
                                            : contentMarkerSizes.NORMAL
                                    }
                                    variant={
                                        contentMarkerVariant === 'photo'
                                            ? contentMarkerVariants.PHOTO
                                            : contentMarkerVariants.VIDEO
                                    }
                                />
                                <Graphic
                                    className="contentMarker_graphic"
                                    widthXs={contentMarkerVariant === 'video' ? 81 : null}
                                    widthMd={contentMarkerVariant === 'video' ? 81 : null}
                                    heightXs={contentMarkerVariant === 'video' ? 28 : 24}
                                    heightMd={contentMarkerVariant === 'video' ? 28 : 24}
                                    variant={
                                        contentMarkerVariant === 'photo' ? graphicVariants.ETA : graphicVariants.EPSILON
                                    }
                                    fillVariant={graphicFillVariants.INVERTED}
                                />
                            </div>
                        )}
                    </div>
                )}
                {(labelToUse || title || name) && (
                    <div className="cardContent" ref={cardContent}>
                        {labelToUse && <div className="cardContent_label" {...labelHtmlProps} />}
                        {title && (
                            <TitleTag className="cardContent_title" id="article_card_title" {...titleHtmlProps} />
                        )}
                        {name && !title && (
                            <TitleTag className="cardContent_title" {...nameHtmlProps} id="article_card_title" />
                        )}
                        {columnist && (
                            <div className="cardContent_columnist">
                                <span>by: </span>
                                <strong {...columnistHtmlProps} />
                            </div>
                        )}
                    </div>
                )}
            </Link>
            {embedded && (
                <Graphic className="cardEmbedded_graphic" widthXs={25} widthMd={25} variant={graphicVariants.THETA} />
            )}
            {(variant === 'beta' || variant === 'eta') && <div style={{ height: ` ${cardJustSpaceHeight}px` }} />}
        </ArticleCardStyled>
    )
}

const articleCardVariants = Object.freeze({
    ALPHA: 'alpha',
    BETA: 'beta',
    GAMMA: 'gamma',
    DELTA: 'delta',
    EPSILON: 'epsilon',
    ZETA: 'zeta',
    ETA: 'eta',
    THETA: 'theta'
})

const articleCardSizeVariants = Object.freeze({
    '1x1': '1x1',
    '1x2': '1x2',
    '1x3': '1x3',
    '2x3': '2x3',
    '1x4': '1x4',
    '1x5': '1x5',
    '1x6': '1x6'
})

const articleCardDecorationVariants = Object.freeze({
    NONE: 'none',
    ALPHA: 'alpha',
    BETA: 'beta',
    GAMMA: 'gamma'
})

const articleCardContentMarkerVariants = Object.freeze({
    NONE: 'none',
    PHOTO: 'photo',
    VIDEO: 'video'
})

const articleCardContentMarkerSizes = Object.freeze({
    SMALL: 'small',
    NORMAL: 'normal',
    LARGE: 'large'
})

ArticleCard.propTypes = {
    item: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    titleTagComponent: PropTypes.elementType,
    imageWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    imageHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    imageVariation: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.shape({}), () => null]),
    imageLazyLoad: PropTypes.bool,
    href: PropTypes.string,
    hrefAs: PropTypes.string,
    className: PropTypes.string,
    inverted: PropTypes.bool,
    rightAlign: PropTypes.bool,
    variant: PropTypes.oneOf([...Object.values(articleCardVariants)]),
    decorationVariant: PropTypes.oneOf([...Object.values(articleCardDecorationVariants)]),
    contentMarkerVariant: PropTypes.oneOf([...Object.values(articleCardContentMarkerVariants)]),
    contentMarkerSize: PropTypes.oneOf([...Object.values(articleCardContentMarkerSizes)]),
    gtmData: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    embedded: PropTypes.bool,
    sizeVariant: PropTypes.oneOf([...Object.values(articleCardSizeVariants)]),
    imagePreload: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    columnist: PropTypes.string
}

ArticleCard.defaultProps = {
    titleTagComponent: 'h3',
    imageWidth: 1200,
    imageHeight: 1200,
    imageVariation: undefined,
    imageLazyLoad: true,
    href: undefined,
    hrefAs: undefined,
    className: undefined,
    inverted: false,
    rightAlign: false,
    variant: articleCardVariants.ALPHA,
    decorationVariant: articleCardDecorationVariants.NONE,
    contentMarkerVariant: articleCardContentMarkerVariants.NONE,
    contentMarkerSize: articleCardContentMarkerSizes.NORMAL,
    sizeVariant: articleCardSizeVariants['1x1'],
    gtmData: undefined,
    embedded: undefined,
    imagePreload: false,
    columnist: undefined
}

export {
    articleCardVariants,
    articleCardSizeVariants,
    articleCardDecorationVariants,
    articleCardContentMarkerVariants,
    articleCardContentMarkerSizes
}

export default withErrorBoundary(ArticleCard, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[ArticleCard]: ', error, componentStack)
    }
})
